import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

import Button from "../components/Button"

const AboutPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="Mi historia" description="Luz Amira Caballero, Nutricionista Dietista egresada de la Universidad Interamericana de Panamá. Actualmente me dedico a la consulta online tanto de niños como de adultos." />


    <div className="bg-white">
      <div className="container pt-12 lg:py-16">
      <h1 className="text-xl mb-4 leading-tight font-bold text-center text-gray-900 sm:mb-12 sm:text-2xl md:text-4xl lg:text-6xl">Sobre mi</h1>
        <div className="flex flex-wrap justify-around items-center">
         
          <div className="pt-6 md:pt-0 md:px-24 md:w-2/3 lg:w-3/4">
            <h1 className="text-lg leading-tight font-bold text-gray-900 text-center md:text-left sm:text-xl md:text-2xl">
              Luz Amira Caballero.
            </h1>
            <div className="mt-6 text-base font-light leading-normal sm:text-lg md:text-lg">
            Soy Nutricionista Dietista egresada de la Universidad Interamericana de Panamá. Actualmente me dedico a la nutrición clinica, especialmente en patologías digestivas, nutrición oncológica, vegetarianos y veganos. A su vez tengo esta plataforma para complementar en brindar recomendaciones, recetas e información con evidencia científica sobre alimentación saludable. 
            </div>
            <a 
                  href="https://koalendar.com/e/luzamira-consulta-nutricional" 
                  className="flex justify-center md:justify-start my-6" 
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  <Button alt="Agenda una cita">Agenda una cita</Button>
              </a>
          </div>
          <Img
            fluid={data.LuzAmira.childImageSharp.fluid}
            alt="Luz Amira"
            className="w-48 h-48 md:w-1/3 lg:w-1/4 md:h-auto rounded-lg mx-auto"
          />
        </div>
      </div>
    </div>

  <div className="bg-white">
    <div className="container py-0 lg:pt-16">
    <h1 className="mt-8 mb-6 md:mt-0 font-bold text-lg md:text-xl sm:text-2xl md:text-4xl lg:text-4xl">Mi formación:</h1>
      <div className="">
        <div className="flex flex-wrap justify-start">
          <div className="w-full md:w-1/2 pr-0 md:pr-8">
            <ul className="list-disc">
              <li className="mt-4 md:mt-8 font-light text-base sm:text-lg md:text-lg ml-8 text-green-600">
                <div className="text-gray-900">
                <p className="font-light text-base sm:text-lg md:text-lg"> Licenciatura en Nutrición y Dietética </p>
                <p className="font-light italic text-base sm:text-base md:text-base">Universidad Interamericana de Panamá</p>
                </div>
              </li>
              <li className="mt-4 md:mt-8 font-light text-base sm:text-lg md:text-lg ml-8 text-green-600">
                <div className="text-gray-900">
                <p className="font-light text-base sm:text-lg md:text-lg"> Certificación Internacional de Alimentación y Nutrición Vegetariana </p>
                <p className="font-light italic text-base sm:text-base md:text-base">Instituto Universitario Vive Sano</p>
                </div>
              </li>
              <li className="mt-4 md:mt-8 font-light text-base sm:text-lg md:text-lg ml-8 text-green-600">
                <div className="text-gray-900">
                <p className="font-light text-base sm:text-lg md:text-lg">Maestría Profesional en Docencia Superior</p>
                <p className="font-light italic text-base sm:text-base md:text-base">Universidad Latina de Panamá -En curso</p>
                </div>
              </li> 
              <li className="mt-4 md:mt-8 font-light text-base sm:text-lg md:text-lg ml-8 text-green-600">
                <div className="text-gray-900">
                <p className="font-light text-base sm:text-lg md:text-lg"> Certificación Internacional Nutrición y Oncología</p>
                <p className="font-light italic text-base sm:text-base md:text-base">Instituto Universitario Vive Sano</p>
                </div>
              </li>
              <li className="mt-4 md:mt-8 font-light text-base sm:text-lg md:text-lg ml-8 text-green-600">
                <div className="text-gray-900">
                <p className="font-light text-base sm:text-lg md:text-lg"> Diplomado en Nutrición Clínica </p>
                <p className="font-light italic text-base sm:text-base md:text-base">Instituto Universitario Vive Sano</p>
                </div>
              </li>         
            </ul>
          </div>

          <div className="w-full md:w-1/2 pl-0 md:pl-8">
            <ul className="list-disc"> 
              <li className="mt-4 md:mt-8 font-light text-base sm:text-lg md:text-lg ml-8 text-green-600">
                <div className="text-gray-900">
                <p className="font-light text-base sm:text-lg md:text-lg"> Curso de Agroecología: Transición hacia sistemas alimentarios sostenibles. </p>
                <p className="font-light italic text-base sm:text-base md:text-base">FAO, Organización de las Naciones Unidas para la Alimentación y la Agricultura.</p>
                </div>
              </li>
              <li className="mt-4 md:mt-8 font-light text-base sm:text-lg md:text-lg ml-8 text-green-600">
                <div className="text-gray-900">
                <p className="font-light text-base sm:text-lg md:text-lg"> Curso Protección social, género y mujeres rurales. </p>
                <p className="font-light italic text-base sm:text-base md:text-base">FAO, Organización de las Naciones Unidas para la Alimentación y la Agricultura.</p>
                </div>
              </li> 
              <li className="mt-4 md:mt-8 font-light text-base sm:text-lg md:text-lg ml-8 text-green-600">
                <div className="text-gray-900">
                <p className="font-light text-base sm:text-lg md:text-lg"> Curso Ambientes alimentarios más saludables y sostenibles. </p>
                <p className="font-light italic text-base sm:text-base md:text-base">FAO, Organización de las Naciones Unidas para la Alimentación y la Agricultura.</p>
                </div>
              </li>
              <li className="mt-4 md:mt-8 font-light text-base sm:text-lg md:text-lg ml-8 text-green-600">
                <div className="text-gray-900">
                <p className="font-light text-base sm:text-lg md:text-lg"> Adolescent Nutrition and Anemia Course. </p>
                <p className="font-light italic text-base sm:text-base md:text-base">Nutrition International.</p>
                </div>
              </li>     
            </ul>
          </div>
        </div>
      <div className=" flex flex-wrap justify-center w-full mt-0 md:mt-8">
        <Img
          fluid={data.Uip.childImageSharp.fluid}
          alt="UIP logo"
          className="w-64 mx-4"
        />
        <Img
          fluid={data.Iuvs.childImageSharp.fluid}
          alt="IUVS logo"
          className="w-64 mx-4"
        />
        <Img
          fluid={data.Fao.childImageSharp.fluid}
          alt="FAO logo"
          className="w-64 mx-4"
        />
        <Img
          fluid={data.Latina.childImageSharp.fluid}
          alt="FAO logo"
          className="w-64 mx-4"
        />
        <Img
          fluid={data.Ni.childImageSharp.fluid}
          alt="NI logo"
          className="w-64 mx-4"
        />
      </div> 
      </div>
    </div>
  </div>

  <div className="bg-gray-50">
      <div className="container py-12 lg:py-16">
        <div className="flex flex-wrap-reverse justify-around items-center">
          <Img
            fluid={data.Stretching.childImageSharp.fluid}
            alt="Luz Amira"
            className="w-48 h-48 md:w-1/3 lg:w-1/4 md:h-auto mx-auto rounded-md"
          />
          <div className="pt-6 md:pt-0 md:px-24 md:w-2/3 lg:w-3/4">
            <h1 className="text-lg leading-tight font-bold text-gray-900 text-center md:text-left sm:text-xl md:text-2xl">
              Mi historia
            </h1>
            <div className="my-6 text-base font-light leading-normal sm:text-lg md:text-lg">
            <p className="mb-2 md:mb-4">
            Mi interés por la nutrición comenzó desde una corta edad, ya que desde pequeña viví rodeada de un estilo de vida vegetariano, el amor a sembrar y cocinar mi propia comida. De este modo surgió preguntarme que beneficios le brindaban los alimentos a mi cuerpo.
            </p>
            <p className="mb-2 md:mb-4">
            Esto que empezó como una curiosidad, reforzó mi interés por la investigación sobre la forma en la que nos alimentamos y me llevó a tomar la decisión de seguir un estilo de vida vegetariano.
            </p>
            <p className="mb-2 md:mb-4">
            Durante mi proceso recibí varios comentarios como: “necesitas comer carne”, “ser vegetariano no es saludable”, “no vas a crecer” entre tantos más que me hacían cuestionarme si realmente estaba alimentándome de una manera adecuada.
            </p>
            <p className="mb-2 md:mb-4">
            Es por esto que decidí estudiar Nutrición y Dietética, para guiar a más personas a llevar un estilo de vida saludable,  promover una alimentación basada en plantas que sea sostenible y a divulgar información cientifica en pro de la salud pública.
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white">
      <div className="container pb-12 lg:py-16">
        <div className="flex flex-wrap items-center">
          <div className="md:w-full lg:w-2/3">
            <h1 className="text-lg leading-tight font-bold text-gray-900 text-center md:text-left sm:text-xl md:text-2xl">
            Áreas en las que te puedo ayudar.
            </h1>
            <ul className="my-6 list-disc w-full">
              <li className="font-light text-base sm:text-lg md:text-lg ml-8 text-green-600"><div className="text-gray-900"> Adquirir o mejorar hábitos de alimentación.</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg ml-8 text-green-600"><div className="text-gray-900"> Alimentación Vegetariana o Vegana (niños y adultos).</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg ml-8 text-green-600"><div className="text-gray-900"> Alimentación para pacientes oncológicos.</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg ml-8 text-green-600"><div className="text-gray-900"> Problemas digestivos.</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg ml-8 text-green-600"><div className="text-gray-900"> Intolerancias Alimentarias.</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg ml-8 text-green-600"><div className="text-gray-900"> Alergias Alimentarias.</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg ml-8 text-green-600"><div className="text-gray-900"> Desequilibrios hormonales.</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg ml-8 text-green-600"><div className="text-gray-900"> Afecciones autoinmunes (Diabetes Tipo 1, Artritis, Anemias, Enfermedad celiaca).</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg ml-8 text-green-600"><div className="text-gray-900"> Enfermedades Metabólicas (Hipertensión, Dislipidemia, Obesidad, Síndrome Metabólico, Diabetes Mellitus T2, Prediabetes)</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg ml-8 text-green-600"><div className="text-gray-900"> Alteración del sueño.</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg ml-8 text-green-600"><div className="text-gray-900"> Mayor rendimiento físico.</div></li>
              <a 
                  href="https://koalendar.com/e/luzamira-consulta-nutricional" 
                  className="flex justify-center md:justify-start mt-4 md:mt-8" 
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  <Button alt="Agenda una cita">Agenda una cita</Button>
              </a>
            </ul>
          </div>
          <Img
            fluid={data.Author.childImageSharp.fluid}
            alt="Luz Amira"
            className="w-48 h-48 md:w-1/3 lg:w-1/3 md:h-auto mx-auto rounded-md"
          /> 
        </div>
      </div>
    </div>

  <Newsletter />
  </Layout> 
)

export default AboutPage

export const query = graphql`
  query {
    LuzAmira: file(relativePath: { eq: "luz-amira.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Stretching: file(relativePath: { eq: "stretching.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight:500 quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Author: file(relativePath: { eq: "author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Uip: file(relativePath: { eq: "uip.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Fao: file(relativePath: { eq: "fao.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Iuvs: file(relativePath: { eq: "iuvs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Latina: file(relativePath: { eq: "latina.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Ni: file(relativePath: { eq: "ni.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }      
  }
`
